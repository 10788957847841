<template>
  <div v-if="selectedDateData">
     <div d-flex v-if="showReservationForm">
        <reservationForm
          id="reservationForm"
          v-bind:dateFromProp="selectedDate"
          returnPage="null"
          v-on:reservation-saved="reservationSavedHandler"
          v-on:cancel="showReservationForm=false"
        >
        </reservationForm>
      </div>
      <div d-flex v-if="showAdminReservationForm">
        <reservationForm
          v-bind:dateFromProp="selectedDate"
          admin="1"
          returnPage="null"
          v-on:reservation-saved="reservationSavedHandler"
          v-on:cancel="showAdminReservationForm=false"
        >
        </reservationForm>
      </div>
    <v-spacer/>
    <v-card v-if="selectedDate && !showAdminReservationForm && !showReservationForm" class="pa-2">
      <v-row>
        <v-col>
          <h2>{{ selectedDate }}</h2>
        </v-col>
        <v-col>
          <v-btn
            v-if="selectedDateIsInFuture"
            color="primary"
            @click="toggleShowReservationForm()"
          >
            <span v-if="!showReservationForm">Přihlásit se</span>
            <span v-else>Skrýt rezervaci</span>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            v-if="
              userProfile.roles &&
              userProfile.roles.manager &&
              selectedDateIsInFuture
            "
            @click="toggleShowAdminReservationForm()"
            color="red"
          >
            <span v-if="!showAdminReservationForm">Přihlásit cizího</span>
            <span v-else>Skrýt rezervaci</span>
            <v-icon>mdi-lock</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn @click="switchMode()" color="gray">Přepni zobrazení </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Vedoucí:
          <span v-if="selectedDateData.leader">{{
            selectedDateData.leader.name
          }}</span
          ><span v-else>nenastaven</span><br />
          Kapacita: {{ selectedDateData.capacity }}<br />
        </v-col>
        <v-col>
          Přihlášených členů: {{ selectedDateData.members }}<br />
          Přihlášených kamarádů: {{ selectedDateData.friends }}<br />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list three-line expand v-if="displayMode == 'BY_RESERVATION'">
            <template v-for="res in selectedDateData.reservations">
              <v-list-item :key="res.id" :dense="true">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ res.from | formatCZDateNoHtml }} -
                    {{ res.to | formatCZDateNoHtml }}</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <span
                      class="body-1"
                      v-for="member in res.membersExpanded"
                      :key="member.id"
                    >
                      <v-tooltip v-if="userProfile.roles.manager" bottom>
                          <template v-slot:activator="{ on, attrs }">
                        <a
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            setAsLeader(res.id, member.id, member.name.print)
                          "
                        >
                          <strong>{{ member.name.print }}</strong
                          >,
                        </a>
                          </template>
                        <span>Nastavit jako vedoucího chaty</span>
                      </v-tooltip>
                      <strong v-else>{{ member.name.print }}, </strong> </span
                    ><br />
                    <span v-if="res.members.length === 0">
                      (kamarádi zapsaní od {{ res.reservedByName }})
                    </span>
                    <span
                      class="body-1"
                      v-for="friend in res.friendsExpanded"
                      :key="friend.id"
                    >
                      {{ friend.name.print }},
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip
                    bottom
                    v-if="
                      userProfile.id == res.reservedBy ||
                      userProfile.roles.manager
                    "
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                    v-on="on"
                      icon
                      color="blue"
                      @click="
                        $router.push(
                          '/reservations/' + res.id + '?return=/calendar'
                        )
                      "
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </template>
                    <span>Upravit rezervaci</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      userProfile.id == res.reservedBy ||
                      userProfile.roles.manager
                    "
                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                    v-on="on"
                      icon
                      color="pink"
                      @click="deleteReservation(res.id)"
                      ><v-icon>mdi-delete-outline</v-icon></v-btn
                    >
                  </template>
                    <span>Smazat rezervaci</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="'d' + res.id"></v-divider>
            </template>
          </v-list>

          <!-- varianta pro vypis -->
          <v-card>
            <v-card-text v-if="displayMode == 'BY_TIME'">
              <table class="dayTable">
                <thead>
                  <tr>
                    <th class="text-left">Pořadí</th>
                    <th class="text-left">Jméno registrovaného</th>
                    <th class="text-left">Datum rezervace</th>
                    <th class="text-left">Kamarád od ..</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="res in reservationsByTime"
                    :key="res.id"
                    :class="res.class"
                  >
                    <td>{{ res.order }}</td>
                    <td>{{ res.name }}</td>
                    <td>{{ res.date | formatCZDateTimeNoHtml }}</td>
                    <td>{{ res.garantName }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="
              userProfile.roles &&
              ( userProfile.roles.manager || userProfile.roles.admin )">
        <v-col>
          <v-card-text>
            <h3>Emaily přihlášených členů pro copy paste</h3>
            <div class="text--secondary">
            {{ membersEmails.join(", ") }}
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
const helpers = require("../helpers.js");
const fb = require("../firebaseConfig.js");
const dbReservations = require("../db-reservations.js");
const logger = require("../fb-logger.js");
const moment = require("moment");

import { mapState } from "vuex";
import reservationForm from "@/components/reservationForm";


export default {
  name: "dayOverview",
  components: {reservationForm},
  props: ['selectedDate'],
  data: () => {
    return {
      showReservationForm: false,
      showAdminReservationForm: false,
      selectedDateData: null,
      displayMode: "BY_RESERVATION", // BY_TIME
    };
  },
  mounted() {
    // console.log("DayOverview mounted");
    this.$store.dispatch("fetchReservationsForMonth");

  },
  watch: {
    calendarLoading: function (newVal, oldVal) {
        // console.log("Calendar loading:"+oldVal+">"+newVal)
        if(oldVal && !newVal && this.selectDate) {
            this.selectDate(this.selectedDate)
        }
    },
    selectedDate: function(newVal){
        this.selectDate(this.selectedDate)
    }
  },
  computed: {
    ...mapState(["userProfile", "calendarLoading", "reservationsPerDay"]),
    reservationsByTime() {
      if (this.displayMode != "BY_TIME") return null;

      logger.debug("[calendarPage] Calculating reservation by time");
      let reservationbyTime = this.selectedDateData.reservations.sort((a, b) =>
        a.createDate.getTime() > b.createDate.getTime() ? 1 : -1
      );
      let out = [];
      let counter = 0;
      for (let i = 0; i < reservationbyTime.length; i++) {
        let res = reservationbyTime[i];
        // console.log(`R: ${res.id} : ${res.createDate}`);
        for (let j = 0; j < res.memberNames.length; j++) {
          //   console.log("-->M:" + res.memberNames[j]);
          out.push({
            order: ++counter,
            date: res.createDate,
            name: res.memberNames[j],
            class:
              counter <= this.selectedDateData.capacity ? "rowGreen" : "rowRed",
          });
        }
      }

      for (let i = 0; i < reservationbyTime.length; i++) {
        let res = reservationbyTime[i];
        console.log(`R: ${res.id} : ${res.createDate}`);
        for (let k = 0; k < res.friendNames.length; k++) {
          console.log("-->F:" + res.friendNames[k]);
          out.push({
            order: ++counter,
            date: res.createDate,
            name: res.friendNames[k],
            garantName: res.reservedByName,
            class:
              counter <= this.selectedDateData.capacity ? "rowGreen" : "rowRed",
          });
        }
      }
      return out;
    },
    selectedDateIsInFuture() {
      return moment(this.selectedDate).isSameOrAfter(
        moment().subtract(1, "day")
      );
    },
    membersEmails() {
      let out=[];
      for(let i=0;i<this.selectedDateData.reservations.length;i++) {
        let res = this.selectedDateData.reservations[i];
        for(let j=0;j<res.membersExpanded.length;j++){
          let email=res.membersExpanded[j].email;
          if(email!=""){
            out.push(email)
          }
        }
      }
      return out;
    },
  },
  methods: {
    selectDate(d) {
      // console.log("selectingDate:"+d)
      let dayStr = moment(new Date(d)).format("YYYY-MM-DD");
      let dayData = this.reservationsPerDay[dayStr];
      this.selectedDateData = dayData;
    },
    switchMode() {
      if (this.displayMode == "BY_RESERVATION") this.displayMode = "BY_TIME";
      else this.displayMode = "BY_RESERVATION";
    },
    setAsLeader(reservationId, memberId, memberName) {
      this.$log.info("setting leader", memberId);

      if (this.selectedDateData.leader) {
        dbReservations
          .unsetLeader(this.selectedDateData.leader.originReservationId)
          .then(() => {});
      }
      this.selectedDateData.leader = { name: "...." };
      dbReservations
        .setLeader(reservationId, memberId, memberName)
        .then((res) => {
          this.$store.dispatch("fetchReservationsForMonth");
        });
    },
    deleteReservation(reservationId) {
      this.$log.info("Deleting reservation:", reservationId);
      fb.reservationCollection
        .doc(reservationId)
        .delete()
        .then(() => {
          this.$log.debug("Deleted", reservationId);
          this.$store.dispatch("fetchReservationsForMonth");
        });
    },
    reservationSavedHandler() {
      this.showReservationForm = false;
      this.showAdminReservationForm = false;
    },
    toggleShowReservationForm() {
      this.showReservationForm = !this.showReservationForm;
    },
    toggleShowAdminReservationForm() {
      this.showAdminReservationForm = !this.showAdminReservationForm;
    },
  },
  filters: helpers.filters,
};
</script>