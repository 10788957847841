const fb = require("./firebaseConfig.js")
const dbPersons = require('./db-persons.js')
const moment = require('moment')
const logger = require("./fb-logger.js")


module.exports = {
    getReservationById: getReservationById,
    expandReservation: expandReservation,
    setLeader: setLeader,
    unsetLeader: unsetLeader,
    renewReservation,
    deleteReservation,
    readFutureReservationList,
    getReservationsBetween
}

function getReservationsBetween(from, to){
    return new Promise((resolve, reject)=>{
        let tofuture = moment(to).add(1,"month").toDate() //HACK. Proste hledam rezervace co maji to daleko v budoucnosti a pak je vyfiltruji
        fb.reservationCollection
        .where("from",">=",from)
        .where("from","<=",to)
        .get()
        .then(snapshot=>{
            let tasks = []
            snapshot.forEach(doc => {
                let res = doc.data()
                // if (res.to.toDate() <= to){
                    tasks.push(expandReservation(res))
                // }
            })
            Promise.all(tasks).then((out) => {
                resolve(out)
            })
        })
    })
}

function renewReservation(resId) {
    return new Promise((resolve, reject) => {
        fb.reservationCollection.doc(resId).update({
                status: "ACTIVE"
            })
            .then(() => {
                resolve("done")
            })
    })
}

function deleteReservation(resId) {
    return new Promise((resolve, reject) => {
        fb.reservationCollection.doc(resId).update({
                status: "DELETED"
            })
            .then(() => {
                resolve("done")
            })
    })
}


function setLeader(resId, memId, memName) {
    return new Promise((resolve, reject) => {
        logger.info("[RESERVATION] Set leader", resId, memId, memName)
        fb.reservationCollection.doc(resId).update({
                leader: {
                    id: memId,
                    name: memName
                }
            })
            .then(() => {
                resolve()
            })
    })
}

function unsetLeader(resId) {
    return new Promise((resolve, reject) => {
        logger.info("[RESERVATION] Unset leader", resId)
        fb.reservationCollection.doc(resId).update({
                leader: null
            })
            .then(() => {
                resolve()
            })
    })
}

function getReservationById(id) {
    return new Promise((resolve, reject) => {
        fb.reservationCollection.doc(id).get().then((snap) => {
            let out = snap.data()
            if (out.oldId && (!out.id)) out.id = out.oldId
            resolve(out)
        }).catch(err => {
            logger.warning("[Reservation] Error getting reservationd for id: ", id, err)
            resolve(null)
        })
    })
}

//nacte moje budouci rezervace dle 
//nebo vsech
//nebo i smazane
function readFutureReservationList(userProfile, showOthersReservation, showDeleted) {
    // console.log("Reading reservations 1")
    return new Promise((resolve, reject) => {
       
        let startDate = moment().subtract(1, 'day').toDate()
        fb.reservationCollection
            // .where("status","==","ACTIVE")
            .where("to", ">=", startDate)
            .get().then((snapshot) => {
                let tasks = []
                snapshot.forEach(doc => {
                    let res = doc.data()
                    if (
                        ((res.reservedBy == userProfile.id) || (showOthersReservation)) //necham moje nebo vsechny, pokud zapnuto
                        &&
                        ((res.status != "DELETED") || showDeleted)) { //necham jenom snazane nebo vsechny
                        tasks.push(expandReservation(res))
                    }
                })
                Promise.all(tasks).then((out) => {

                    resolve(out)
                })

            })
    })
}

// function expandReservation(res){
//     //console.log("Expanding reservation "+res.id)
//     return new Promise((resolve,reject)=>{
//         res.from=new Date(res.from.toDate())
//         res.to=new Date(res.to.toDate())
//         res.createDate=moment(res.createDate.toDate())

//         dbPersons.expandMembersFromOldId(res.members)
//         .then((arrm)=>{
//             res.membersExpanded = arrm
//             return dbPersons.expandFriendsFromOldId(res.friends)
//         })
//         .then((arrf)=>{
//             res.friendsExpanded = arrf
//             resolve(res)
//         })
//     })
// }

function expandReservation(res) {
    return new Promise((resolve, reject) => {
        // console.log("Expanding reservation " + res.id)
        // console.log(res)

        if (!res) resolve({})

        if (!res.nights) res.nights = Math.ceil((res.to.seconds - res.from.seconds) / (3600 * 24))

        res.from = res.from.toDate()
        res.to = res.to.toDate()
        res.createDate = res.createDate.toDate()

        //expand resreved by
        fb.membersCollection.doc(res.reservedBy).get().then((snapshot) => {

                let out = snapshot.data()
                if (!out) out = {
                    login: "OLD ID:" + res.reservedBy
                }

                // console.log("Reserved by  loaded:"+out )
                res.reservedByExpanded = out
                res.reservedByName = out.name.print
                //expand members and friends to names
                return dbPersons.expandMembersFromOldId(res.members)
            })
            .then((arrm) => {
                // console.log(arrm)
                res.membersExpanded = arrm
                res.memberNames = arrm.map(item => item.name.print)
                return dbPersons.expandFriendsFromOldId(res.friends)
            })
            .then((arrf) => {
                res.friendsExpanded = arrf
                res.friendNames = arrf.map(item => item.name.print)
                resolve(res)
            })
    })
}