import Vue from 'vue'
import Vuex from 'vuex'
const moment = require("moment-timezone")
const fb = require('./firebaseConfig.js')
const dbReservations = require("./db-reservations.js")
const dbPersons = require('./db-persons.js')
const logger=require('./fb-logger.js')

Vue.use(Vuex)

const MAXCAPACITY=40

// handle page reload
fb.auth.onAuthStateChanged(user => {
    if (user) {
        //console.log("Store: fb.auth.onAuthStateChanged:"+user.uid+"("+user.email+")")
        store.commit('setCurrentUser', user)
        store.dispatch('fetchUserProfile')
    }
})

export const store = new Vuex.Store({
    state: {
        userLoading: false,        
        calendarLoading: false,
        currentUser: null,
        userProfile: {},
        selectedMonth: new Date(), //aktualni datum v kalendari
        reservationsPerDay: {},    //pole s klici typu 2018-08-01, kde jsou data pro kazdy den
        actionEvents:[], //pole delsich rezervaci
    },
    actions: {  
        fetchUserProfile({ commit, state }) {
            logger.info("[store] fetching userprofile for ",state.currentUser.uid)
            commit('setUserLoading',true)
            var userProfile={}

            getMemberDetail(state.currentUser.uid, true)
            .then((data) => {
                userProfile = data;
                logger.info("[store] user profile found: ", userProfile.email)
                if(!userProfile.preferences){
                    userProfile.preferences = {
                        gdprConsent: {
                            state: "UNKNOWN",
                            date: null,
                            form: ""
                        },
                        emails:{
                            reservation: true,
                            news: true
                        }
                    }
                }

                // console.log("\tExpanding canBeSuncsribed")
                return dbPersons.expandMembersFromOldId(userProfile.canBeSubscribed)
            })
            .then((data) => {
                // console.log("canBeSubscribedExpanded:"+JSON.stringify(data))
                userProfile.canBeSubscribedExpanded = data
                // console.log("\tExpanding canSubscribe")
                return dbPersons.expandMembersFromOldId(userProfile.canSubscribe)
            })
            .then((data) => {
                // console.log("canSubscribeExpanded:"+JSON.stringify(data))
                userProfile.canSubscribeExpanded = data
                // console.log("\tExpanding group")
                return getGroupMembers(userProfile.group)
            })
            .then((data) => {
                // console.log("GROUP:"+JSON.stringify(data))
                userProfile.groupMembersExpanded = data;
                // console.log("\tExpanding friends")
                return getFriendForMember(userProfile.id)
            })
            .then((data) => {
                // console.log("FRIENDS:"+JSON.stringify(data))
                userProfile.friendsExpanded = data;
                return getNewMessages(userProfile.email)
            })
            .then((data) => {
                userProfile.newMessages = data
                // console.log("[done]Commiting new user profile")
                try{
                    Rollbar.configure({
                        payload: {
                            person: {
                              id: userProfile.id,
                              username: userProfile.name.print,
                              email: userProfile.email
                            }
                        }
                    })
                } catch(e){
                    //TODO
                }
                commit('setUserProfile',userProfile)
                commit('setUserLoading',false)
            })
            .catch(err=>{
                logger.error("[store] fetchUserProfile: ",err)
                commit('setUserLoading',false)
            })
        },
        clearData({commit}){
            commit('setCurrentUser',null)
            commit('setUserProfile',{})
        },
        fetchReservationsForMonth({commit, state}){
            commit('setCalendarLoading',true)
            getReservationsForMonth(state.selectedMonth).then((calendar)=>{
                commit('setReservationsPerDay',calendar)
                return getActionsForMonth(state.selectedMonth)
            })
            .then((events)=>{                
                commit ('setActionEvents', events) 
                commit('setCalendarLoading',false)
            })
        }
    }, 
    mutations: {
        setUserLoading(state, val) {
            state.userLoading = val
        },
        setCalendarLoading(state, val) {
            state.calendarLoading = val
        },
        setCurrentUser(state, val) {
            state.currentUser = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
        },
        setReservationsPerDay(state, val){
            state.reservationsPerDay = val
        },
        setSelectedMonth(state, val){
            state.selectedMonth = val
        },
        setActionEvents(state, val){
            state.actionEvents = val
        }
    }
})


/************************ user profile helpers *********************************/

function getNewMessages(email){
    return new Promise((resolve,reject)=>{
        fb.userActions.where("userEmail","==",email)
        .where("eventType","==","pageView")
        .where("eventSource","==","/home")
        .orderBy("timestamp","desc").limit(1)
        .get()
        .then(function(querySnapshot) {
            let lastts=new Date("2020-10-01"); // maximal last visit
            querySnapshot.forEach(function(doc) {
                lastts=doc.data().timestamp;
            });
            // console.log("Last actionts:"+lastts)
            return lastts
        })
        .then((lastaction)=>{
            resolve(1) //TODO: SPOCITAT KOLIK JE TAM NOVYCH ZPRAV
        })
        .catch(function(error) {
            reject()
            console.log("Error getting documents: ", error);
        });
    })
}

function getGroupMembers(group) {
    // console.log("Reading members of group:" + group)
    return new Promise((resolve, reject) => {
        if ((!group) || (group == "NOGROUP")) {
            resolve([])
        } else {
            fb.membersCollection
                .where("group", "==", group)
                .where("active", "==", true)
                .orderBy("name.last")
                .get()
                .then((snapshot) => {
                    let out = []
                    snapshot.forEach(doc => {
                        out.push(doc.data())
                    })
                    resolve(out)
                })
        }
    })
}

function getMemberDetail(uid) {
    logger.info("[store.getMemberDetail] reading member: " + uid)
    return new Promise((resolve, reject) => {
        if (!uid) resolve({})
        
        fb.membersCollection.where("firebaseUserId","==",uid).get().then((res) => {
            var out={}
            res.forEach(doc=>{
                out = doc.data()
            })  
            logger.debug("[store.getMemberDetail] Member found:"+JSON.stringify(out));
            resolve(out)
        })
    })
}

//TODO. Upravit tohle podle pratel
function getFriendForMember(uid) {
    // console.log("reading friends for: " + uid)
    return new Promise((resolve, reject) => {
        if (!uid) resolve([])

        fb.friendsCollection.where("grantedBy", "==", uid).get().then((snapshot) => {
            let out = []

            snapshot.forEach(doc => {
                out.push(doc.data())
            })
            // console.log(out)
            resolve(out)
        })
    })
}

/*************************** reservation helpers *******************************/
//nacteseznam akci pro dane obdobi
function getActionsForMonth(date){
    // console.log("Reading events ..")
    return new Promise((resolve, reject)=>{
        fb.eventsCollection.get().then((snap)=>{
            let out=[]
            snap.forEach((doc)=>{
                let e=doc.data()
                e.startDate = e.startDate.toDate();
                e.endDate = e.endDate.toDate();
                
                out.push(e) //TODO: filter month!!!
            })
            // console.log("Total events: "+out.length)
            resolve(out)
        }).catch(e=>{
            console.log("ERROR:"+e)
        })
    })
}

//seznam rezervaci po dnech
function getReservationsForMonth(date){
    return new Promise((resolve, reject)=>{
    console.log("Reading resevation for month "+date)
    var y = date.getFullYear(), 
    m = date.getMonth();

    var startDate = moment(new Date(y, m, 1)).subtract(1,"months")
    var endDate = moment(new Date(y, m, 1)).add(2,"months")
    getActionsForMonth(date)
    .then(actions=>{
        return getReservationsForDateRange(startDate, endDate, actions)
    })
    .then(data => {
            //convert to day array
            // console.log("Found actions:"+data.actions.length)
            // console.log("Found reservations:"+data.reservations.length)
            // console.log(data.reservations)
            let resExpanded = data.reservations
            let actions = data.actions
            let cal = {}
            for(let actualDay = moment(startDate); actualDay.isBefore(endDate); actualDay.add(1,"day")){
                // console.log("Procesing reservations for:"+actualDay.format())
                let capacity=MAXCAPACITY
                for(let i=0;i<actions.length;i++) {
                    let action=actions[i]
                    //console.log(action)
                    if(actualDay.isBetween(moment(new Date(action.startDate.getTime()-12*3600*1000)), moment(new Date(action.endDate.getTime()+12*3600*1000)))){
                        // console.log(">"+action.title+":"+action.people)
                        if(capacity !== undefined) capacity = action.capacity
                    }
                }

                let dayEntry = {
                    leader: null,
                    date: actualDay.toDate(),
                    reservations: [],
                    capacity: capacity,
                    total: 0,
                    members: 0,
                    friends:0
                }
                resExpanded.forEach(res=>{
                    // console.log(actualDay.toDate()+"|reservation: "+res.reservedByName+"|"+res.from+"|"+res.to)
                    if(actualDay.isBetween(moment(res.from).subtract(20,"hour"), moment(res.to).subtract(1,"day"))){
                        if(res.leader && res.leader.id){
                            dayEntry.leader = res.leader
                            dayEntry.leader.originReservationId = res.id //zapamatujeme si z jake rezervace je
                        }
                        dayEntry.reservations.push(res)
                        dayEntry.total += (res.membersExpanded.length+res.friendsExpanded.length)
                        dayEntry.members += res.membersExpanded.length 
                        dayEntry.friends += res.friendsExpanded.length

                        //calculate capacity - HACK
                        // let dayCode=actualDay.format("YYYY-MM-DD");
                        // if((dayCode>="2019-12-22") && (dayCode<="2020-01-03")){
                        //     dayEntry.capacity = 40;
                        // } 
                    }
                })
                cal[actualDay.format("YYYY-MM-DD")] = dayEntry
            }
            //console.log(cal)
            resolve(cal)
        })
    })
}


//ziska rezervace z obdobi v datem rozsahu a vrati je podle dnu
export function getReservationsForDateRange(startDate, endDate, actions=[]){
    return new Promise((resolve, reject) =>{
        
        // console.log(console.log("Reading resevation from "+startDate.format()+ "  to "+endDate.format()))
        // logger.debug("Reservations: <",startDate.format()," - ",endDate.format(),">")
        fb.reservationCollection
            .where("from",">=",startDate.toDate())
            .where("from","<=",endDate.add(1,"day").toDate())    
            .get().then(snapshot=>{
                let reservations = []
                snapshot.forEach(doc =>{
                    var reservation = doc.data()
                    if(reservation.status!="DELETED"){
                        reservations.push(reservation)
                    }
                })
                // logger.debug("Found reservations: ",reservations.length)
                
                let tasks=[]
                reservations.forEach(res=>{
                    tasks.push(dbReservations.expandReservation(res))
                })

                return Promise.all(tasks)  
            }).then(reservations=>{
                resolve({
                    reservations: reservations,
                    actions: actions
                })
            })
        })
}




